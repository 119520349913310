export function OpenWindowWithPost(url, windowoption, name, params)
{
  var form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute("action", url);
  form.setAttribute("target", name);

  for (var i in params) {
      if (params.hasOwnProperty(i)) {
        if(Array.isArray(params[i])) {
          for(var x in params[i]) {
            var input1 = document.createElement('input');
            input1.type = 'hidden';
            input1.name = i+'[]';
            input1.value = params[i][x];
            form.appendChild(input1);
          }
        } else {
          var input = document.createElement('input');
          input.type = 'hidden';
          input.name = i;
          input.value = params[i];
          form.appendChild(input);
        }
      }
  }
  document.body.appendChild(form);
  
  //note I am using a post.htm page since I did not want to make double request to the page 
//it might have some Page_Load call which might screw things up.
  window.open("post.htm", name, windowoption);
  
  form.submit();
  
  document.body.removeChild(form);
 }