<template>
  <div class="container-fluid card-body">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col">
        <h1>Treinamentos</h1>
      </div>
      <div class="col">
        <router-link to="treinamentos/cadastro" v-if="parseInt(permissoes['Cadastro de treinamentos']) >= 2">
          <button type="button" class="btn btn-primary float-right btn-hover btn-dark-color">CADASTRAR</button>
        </router-link>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="input-group">
          <input type="text" class="form-control float-left border-right-0"
          v-on:keyup.enter="filteredData"
          v-model="searchText" placeholder="Pesquisar">
          <span class="input-group-append" @click="filteredData">
              <div class="input-group-text bg-transparent"><i class="fa fa-search"></i></div>
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <MyTable
          :data="filteredList"
          :columns="columns"
          :itemsPerPage="10">
          <template v-slot:row="{ row }">
            <td>
              {{row.codigo}}
            </td>
            <td>
              {{row.nome_atividade}}
            </td>
            <td>
              {{row.data_inicio | maskdateptbr}} à {{row.data_fim | maskdateptbr}}
            </td>
            <td>
              {{row.carga_horaria_pratica.substr(0,5)}}h prática e {{row.carga_horaria_teorica.substr(0,5)}}h teórica
            </td>
            <td>
              {{row.observacao}}
            </td>
            <td class="float-right">
              <drop-down title="Configurações" class="mr-3"  v-if="permissoes['Cadastro de treinamentos'] >= 1">
                <template #title>
                  <i class="fas fa-print mb-3 btn btn-dark-color text-white" title="Imprimir treinamento"></i>
                </template>
                  <div class="row mx-0" style="width:18vw;">
                    <div class="col-12 px-5 py-2 dropdown-item" @click="imprimir(row.id, false)">
                      <span >Imprimir treinamento</span>
                    </div>
                    <div class="col-12 px-5 py-2 dropdown-item" @click="imprimir(row.id, true)">
                      <span>Imprimir em branco</span>
                    </div>
                  </div>
              </drop-down>
              <!--<i class="fas fa-print mr-3 mb-3 btn btn-dark-color text-white" title="Imprimir treinamento" @click="imprimir(row.id)"></i> -->
              <router-link :to="{ name: 'TreinamentoCertificados', query: { treinamento: row.id }}"   v-if="permissoes['Cadastro de treinamentos'] >= 2"><i class="fas fa-medal mr-3 mb-3 btn btn-dark-color text-white" title="Imprimir certificados"></i></router-link>
              <router-link :to="{ name: 'TreinamentosFaltas', query: { treinamento: row.id }}"   v-if="permissoes['Cadastro de treinamentos'] >= 2"><i class="fas fa-user-slash mr-3 mb-3 btn btn-danger text-white" title="Registrar faltas"></i></router-link>
              <i class="fas fa-users mr-3 mb-3 btn btn-info" title="Cadastrar funcionários" @click="cadastrarFuncionarios(row)"  v-if="permissoes['Cadastro de treinamentos'] >= 2"></i>
              <i class="fas fa-edit mr-3 mb-3 btn btn-warning " title="Editar treinamento" @click="editarItem(row.id)"  v-if="permissoes['Cadastro de treinamentos'] >= 2"></i>
              <i class="fas fa-trash-alt mr-3 mb-3 btn btn-danger" title="Deletar treinamento" @click="deletarItem(row)"  v-if="permissoes['Cadastro de treinamentos'] > 2"></i>
            </td>
            
          </template>
        </MyTable>
      </div>
    </div>
  </div>
</template>

<script>
import MyTable from './../../uicomponents/MyTable'
import treinamentoRepository from './../../services/api/treinamentoRepository'
import {OpenWindowWithPost} from './../../services/windowPost.service'
import constants from './../../../constants'
export default {
  name: 'Empresas',
  components: {
    MyTable
  },
  data(){
    return {
      searchText: '',
      isLoading: false,
      columns: [
        {
          label: 'Evento',
          field: 'codigo',
          sortable: 'codigo'
        },
        {
          label: 'Nome atividade',
          field: 'nome_atividade',
          sortable: 'nome_atividade'
        },
        {
          label: 'Período',
          field: 'data_inicio',
          sortable: ''
        },
        {
          label: 'Duração',
          field: 'carga_horaria',
          sortable: ''
        },
        {
          label: 'Observação',
          field: 'observacao',
          sortable: ''
        },
        {
          label: 'Ações',
          field: 'actions',
          sortable: ''
        }
      ],
      treinamentos: [],
      filteredList: [],
      permissoes: null
    }
  },
  created () {
    this.fetch()
    console.log(this.$store.getters.permissoes['Treinamento'])
    this.permissoes = this.$store.getters.permissoes['Treinamento'] ?? []
  },
  methods: {
    filteredData () {
      let filterKey = this.searchText && this.searchText.length > 0 && this.searchText.toLowerCase()
      console.log(this.treinamentos)
      let data = [...this.treinamentos]
      
      if (filterKey) {
        data = data.filter(function (row) {
          if(String(row.codigo).indexOf(filterKey) > -1 || row.nome_atividade.toLowerCase().indexOf(filterKey) > -1) {
            return row
          }
        })
      }
      this.filteredList = [...data]
      //console.log(this.filteredList)
      if(this.filteredList.length == 0) {
        this.fetch(filterKey)
      }
    },
    imprimir (idTreinamento, emBranco = false) {
      var param = { idTreinamento : idTreinamento, template : emBranco}
      OpenWindowWithPost(`${constants.API_URL}/impressao/treinamento`, 
      "", 
      "NewFile", param);
    },
    cadastrarFuncionarios (treinamento) {
      this.$router.push({ name: 'TreinamentosFuncionarios', query: { treinamento: treinamento.id } })
    },
    editarItem (id) {
      this.$router.push({ name: 'TreinamentosCadastro', query: { id: id } })
    },
    deletarItem (treinamento) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover treinamento',
        text: `Deseja deletar a ${treinamento.nome}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarTreinamento(treinamento)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Registro removido com sucesso!'
          }).then(() => {
            _this.fetch()
          })
          
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarTreinamento (treinamento) {
      this.isLoading = true
      treinamentoRepository.deletarTreinamento(treinamento.id).then((response) => {
        this.isLoading = false
        return response.data['success']
      }).catch(() => {
        this.isLoading = false
        return false
      })
    },
    fetch (search = null) {
      this.isLoading = true
      treinamentoRepository.listarTreinamentos(search).then(response => {
        this.filteredList = this.treinamentos = response.data['data']
        //console.log(this.filteredList)
        this.isLoading = false
      }).catch( () => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style>

</style>